module.exports = {
    BASE_URL: 'http://testapi.top-people.me/',
    API_PATH: 'admin/',

    KAKAO_REST_API_KEY: '399a62d30dcfab158399768c6b1deeaf',
    HEADER: {
        AUTH_TOKEN: 'x-access-token',
        CONTENT_TYPE: {
            NAME: 'Content-Type',
            JSON: 'application/json',
            FILE: 'multipart/form-data',
            FORM: 'application/x-www-form-urlencoded'
        },
        LANG: 'Accept-Language',
    },

    //. 응답코드
    RESULT_CODE: {
        SUCCESS: 0,                     //. 정상
        EMPTY_TOKEN: -1,                //. 토큰정보 없음.
        TOKEN_EXPIRED: -2,              //. 토큰 기한 만료
        EMPTY_PARAM: -3,                //. 요청파라미터 누락
        NEED_CHARGE: -4,                //. 하트부족, 상점가기 (포인트는 충전없음)
        NOT_AUTHORIZED: -5,             //. 호출권한이 없음 (IP, LEVEL 권한부족)
        ERROR: -10,                     //. api 내부 오류
    },

    //. 사용자 상태코드 (t_member.status)
    USER_STATUS: {
        BLOCK: -4,                      //. 불량
        LEAVE: -3,                      //. 탈퇴
        SLEEP: -2,                      //. 휴면
        REJECT: -1,                     //. 인증반려
        PENDING: 0,                     //. 인증대기
        NORMAL: 1,                      //. 정상
    },

    //. 심사요청 타입 (t_member_pending.type)
    USER_PENDING_TYPE: {
        PROFILE_IMAGE: 'profile_image', //. 프로필이미지
        SCHOOL: 'school',               //. 학교
        JOB: 'job',                     //. 직장
        MONEY: 'money',                 //. 재력
        STAR: 'star',                   //. 연예인/스타
        INTRO: 'intro',                 //. 자기소개
    },

    //. 심사요청 타입 (t_member_pending.status)
    USER_PENDING_STATUS: {
        REJECT_RETRY: -3,               //. 반려 재요청
        CANCEL: -2,                     //. 사용자취소
        REJECT: -1,                     //. 반려
        PENDING: 0,                     //. 인증대기
        NORMAL: 1,                      //. 정상
    },

    USER_PENDING_CATEGORY: {
        EMAIL: 'email',
        IMAGE: 'image',
        TEXT: 'text',
    },
};